<template>
  <div id="legal">
    <section class="section">
      <div class="container  my-5 py-5">
        <h1>Impressum</h1>
        <h2>Verantwortlich für den Inhalt dieser Website</h2>
        <br />
        <p>
          Kinesiologie Martina
          <br />
          Besitzerin: Martina Lösch
          <br />
          Wohnpark Ager 17
          <br />
          4860 Lenzing
          <br />
          Österreich
          <br />
          Telefon: +43 699 10994684
          <br />
          hello@kinesiologie-martina.at
          <br />
          www.kinesiologie-martina.at
        </p>

        <p>
          Anwendbares Recht: Gewo
          <br /><br />
          Aufsichtsbehörde: Bezirkshauptmannschaft Vöcklabruck
          <br /><br />
          Wirtschaftskammer: WKO Vöcklabruck / Energetiker-Gewerbe
          <br /><br />
          Info lt. EKG
          <br /><br />
          Bildverweis:
          <br />
           Martina Lösch
          <br /><br />
          Texte, Bilder und Grafiken auf dieser Website dürfen nur mit
          ausdrücklicher Genehmigung des Eigentümers verwendet werden.
          <br />
          Alle Informationen und Erklärungen auf unserer Website sind
          unverbindlich. Wir übernehmen keine Garantie für die Richtigkeit und
          Vollständigkeit des Inhalts. Aus dem Inhalt dieser Website können
          keine Rechtsansprüche entstehen. Wenn Sie Fehler im Inhalt bemerken,
          kontaktieren Sie uns bitte, damit wir diese korrigieren können. Wir
          identifizieren uns nicht mit dem Inhalt der Seiten oder Unterseiten,
          auf die verwiesen wird, und übernehmen keine Haftung dafür. Wenn eine
          der Seiten, auf die wir verlinken, fragwürdigen Inhalt hat oder vom
          jeweiligen Seiteninhaber nicht gewünscht wird, wird der jeweilige Link
          sofort gelöscht.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "legal-comp",
  data() {
    return {};
  },
  components: {},
  computed: {},
  watch: {},
  methods: {},
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>

</style>
