<template>
  <div id="dataprotection">
    <section class="section">
      <div class="container my-5 py-5">
        <h1>Erklärung zur Informationspflicht (Datenschutzerklärung)</h1>
        <p>
          Um Ihre persönlichen Daten zu schützen, werden Informationen
          ausschließlich aufgrund der gesetzlichen Bestimmungen (DSGVO, TKG
          2003) verarbeitet. Lesen Sie hier die wichtigsten Aspekte der
          Datenverarbeitung auf dieser Website.
        </p>
        <br/><br/><h3>Kontakt mit uns</h3>
        <p>
          Eine über das Formular auf der Website oder per Email eingereichte
          Anfrage wird samt ihren eingegebenen Daten für Anschlussfragen sechs
          Monate gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung
          weiter.
        </p>
        <br/><br/><h3>Server-Log-Files</h3>
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log Files, die Ihr Browser automatisch an uns
          übermittelt. Diese sind:
        </p>
        <ul>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
          <li>Browsertyp und Browserversion</li>
          <li>verwendetes Betriebssystem</li>
          <li>Referrer URL</li>
        </ul>

        <p>
          Diese Daten sind anonymisiert und werden nicht mit anderen
          Datenquellen zusammengeführt. Wenn wir konkrete Anhaltspunkte für eine
          rechtswiedrige Nutzung haben, behalten wir uns vor, diese Daten
          nachträglich zu überprüfen.
        </p>
        <br/><br/><h3>Services</h3>
        <p>
          Für die einwandfreie Funktion dieser Website verwenden wir in
          folgenden Bereichen Services von Drittanbietern:
        </p>

        <ul>
          <li>
            Webhosting (Google Hosting) zur Speicherung von Daten, zur
            Ausführung der Website
          </li>
         
        </ul>
        <br/><br/><h3>Skripte</h3>
        <p>
          Ein Skript ist eine Datei mit Befehlen, welche Funktionen zur
          Verfügung stellt, Daten verarbeitet und unsere Website an die Services
          von Drittanbietern wie z.B. die Webanalyse-Produkte Google Analytics
          anbindet. Zum Betrieb unserer Website verwenden wir Skripte von
          folgenden Drittanbietern:
        </p>
        <ul>
          <li>Google Analytics zur Webanalyse</li>
        </ul>

        <p>
          Da diese Website auch ohne diese Skripte einwandfrei funktioniert,
          werden diese erst nach Einwilligung gestartet.
        </p>

        <br/><br/><h3>Cookies</h3>
        <p>
          Wir verwenden Cookies. Ein Cookie ist eine kleine Textdatei, die im
          Browser ihres Endgerätes gespeichert werden. Sie sind nicht schadhaft
          und haben lediglich die Aufgabe, die Nutzererfahrung reibungsloser zu
          gestalten. Manche Cookies haben Ablaufdaten, und andere bleiben auf
          dem Gerät gespeichert, bis sie gelöscht werden. Eine Funktion, die
          Cookies erfüllen ist es, das Gerät beim nächsten Besuch wieder zu
          erkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so
          einrichten, dass er Sie über das Setzen von Cookies informiert und Sie
          dies nur im Einzelfall erlauben. Bei der Deaktivierung von Cookies
          kann die Funktionalität unserer Website eingeschränkt sein. Zum
          Betrieb unserer Website verwenden wir zudem Cookies von folgenden
          Drittanbietern:
        </p>
       
        <br/><br/><h3>Ihre Rechte</h3>
        <p>
          Bezüglich der Datenspeicherung haben Sie die Rechte auf Auskunft,
          Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf
          und Widerspruch. Wenn Sie der Ansicht sind, dass die Verarbeitung
          Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
          datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden
          sind, können Sie sich bei uns oder der Datenschutzbehörde Beschwerde
          einreichen.
        </p>

        <p>
          Kontaktieren Sie uns:<br />
          Martina Lösch <br />
          hello@kinesiologie-martina.at<br />
          +43 699 10994684<br />
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "dataprotection-comp",
  data() {
    return {};
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  components: {},
  computed: {},
  watch: {},
};
</script>
<style scoped>

</style>
